/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap'); */
/* 
* {
  box-sizing: border-box;
} */

body {
  scroll-behavior: smooth;
}

div#root {
  position: relative;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: #f7f6fb;
  justify-content: flex-start;
  padding-bottom: 6rem;
  overflow: hidden;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  font-family: 'Poppins';
  padding: 1rem;
  /* margin-right: -50%; */
  transform: translate(-50%, -50%);
  z-index: 99;
  background-color: #fff;
  color: '#152540';
  border-radius: 10px;
  /* h2 {
    color: white;
  } */
  /* @media (min-width: 768px) {
    max-width: 350px;
  } */
}

.Modal:focus {
  outline: none;
}

h2.modal-title {
  text-align: center;
  font-family: 'Poppins Bold';
  margin: 0;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 28, 28, 0.8);
  z-index: 99;
}
